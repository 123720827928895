<template>
  <div class="consumer-form-container">
    <div class="consumer-form-main-section">
      <div class="form-title">
        <div class="section-h2 desk-view">
          {{ $t('Register.ApplyFormTitle') }}-{{ $t('Register.Consumer') }}
        </div>
        <div class="section-h2 pad-phone-view">
          {{ $t('Register.ApplyFormTitle') }}
          <br>
          {{ $t('Register.Consumer') }}
        </div>
      </div>
      <div>
        <el-form 
          ref="registerConsumerForm"
          :model="registerConsumerForm"
          label-position="top"
          :rules="rules"
          class="demo-ruleForm"
        >
          <el-form-item
            v-for="(item, index) in formInputField"
            :key="index"
            :label="$t(`accountManagement.${item.label}`)"
            :prop="item.prop"
          >
            <el-input
              v-model="registerConsumerForm[item.prop]"
              autocomplete="off"
              :aria-placeholder="$t(`Register.${item.placeholder}`)"
              :placeholder="$t(`Register.${item.placeholder}`)"
            />
          </el-form-item>
          <el-form-item
            id="descripUsageLabel"
            :label="$t('Register.placeholderDescripUsage')"
            prop="suggestion"
          >
            <el-input
              v-model="registerConsumerForm.suggestion"
              aria-labelledby="descripUsageLabel"
              type="textarea"
            />
          </el-form-item>
          <div class="infoagree-area">
            <div class="infoagree-desc">
              <span>
                <i 
                  v-if="registerConsumerForm.is_check_terms_of_use.length"
                  :aria-label="$t(`Aria.UnCheckTerms`)"
                  class="el-icon-circle-check checked"
                />
                <i 
                  v-else
                  :aria-label="$t(`Aria.TermsChecked`)"
                  class="el-icon-remove-outline noncheck" 
                />
              </span>
              {{ $t(`Register.pleaseReadAndCheck`) }}
                
            </div>
            <el-button
              class="infoagree-button"
              type="button"
              @click="showPersonalInfoAgreeDialog"
            >
              {{ $t('Register.TremsOfUse') }}
            </el-button>
            <div 
              v-if="showUncheckPersoInfoAgree"
              class="noncheck-notify"
            >
              {{ $t('Notify.UncheckPersoInfoAgree') }}
            </div>
          </div>
        </el-form>
      </div>
      <div class="form-footer">
        <span class="register-contactus">
          {{ $t('Register.NeedHelp') }}
          <router-link
            style="width:100%;"
            :to="{ name: 'ContactUs' }"
          >
            {{ $t('Register.ContactUs') }}
          </router-link>
        </span>
        <div>
          <el-button
            class="button-light"
            type="button"
            @click="handleCancel"
          >
            {{ $t('general.Cancel') }}
          </el-button>
          <el-button
            class="button-dark"
            type="button"
            @click="submitForm('registerConsumerForm')"
          >
            {{ $t('general.Apply') }}
          </el-button>
        </div>
      </div>
      <!-- 服務條款 -->
      <el-dialog
        :visible.sync="dialogVisiblePersonalInfoAgree"
        :modal="false"
        class="infoagree-dialog"
        width="80%"
        top="10vh"
        center
      >
        <div slot="title">
          <div class="section-h2">{{ $t('TermsOfService.Title_Subject') }}-{{ $t('Register.DataConsumer') }}</div>
        </div>
        <div ref="dialogContent" class="infoagree-content">
          <el-button 
            v-if="showScrollToBottom"
            class="scroll-bottom"
            type="text"
            icon="el-icon-caret-bottom"
            @click="scrollToBottom"
          >
            {{ $t('Register.pleaseCheckAgree') }}
          </el-button>
          <TermsOfService />
          <el-divider />
          <PersonalInfoAgree />
          <div class="checkbox-container">
            <el-checkbox 
              v-model="registerConsumerForm.is_check_terms_of_use"
              @change="handleCheckAndClose"
            >
              <p class="checkbox-text">{{ $t('Register.IReadAndAgree') }}</p>
            </el-checkbox>
          </div>
        </div>
      </el-dialog>
      <!-- 申請成功 -->
      <el-dialog
        :title="$t(`Register.ApplySuccess`)"
        :visible.sync="dialogVisible_applySuccess"
        :width="applySuccessDialogWidth"
        :close-on-click-modal="false"
        :before-close="handleCloseSuccess"
      >
        <div>{{ $t('Register.RegisterConsumerNotify1') }} [<strong> {{ $t('Register.Consumer') }}</strong>] ！
        </div>
        <div>{{ $t('Register.RegisterConsumerNotify2') }} </div>
        <br>
        <div>{{ $t('Register.RegisterConsumerNotify3') }} <a 
          href="mailto:service@data-sports.tw"
          aria-label="開啟平台聯絡信箱"
          class="text-blue"
        >{{ $t('general.ContactUs') }}</a>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button
            class="button-dark"
            type="button"
            @click="handleCloseSuccess"
          >
            {{ $t('general.Confirm') }}
          </el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { registerConsumer } from '@/api/member'
import { Resize } from '@/Mixins'
import PersonalInfoAgree from '@/components/PersonalInfoAgree'
import TermsOfService from '@/components/TermsOfService'

export default {
  name: 'ConsumerForm',
  components: {
    PersonalInfoAgree,
    TermsOfService
  },
  mixins: [Resize],
  data () {
    return {
      rules: {
        name: [
          { 
            required: true,
            message: this.$t('Notify.pleaseInput') + this.$t('accountManagement.memberName'),
            trigger: 'blur'
          }
        ],
        email: [
          { 
            required: true,
            message: this.$t('Notify.pleaseInput') + this.$t('accountManagement.email'),
            trigger: 'blur'
          },
          { 
            type: 'email',
            message: this.$t('Notify.pleaseInput') + this.$t('accountManagement.email'),
            trigger: 'blur'
          }
        ],
        suggestion: [
          { 
            required: true,
            message: this.$t('Register.placeholderDescripUsage'),
            trigger: 'blur'
          }
        ]
      },
      applySuccessDialogWidth: '40%',
      dialogVisiblePersonalInfoAgree: false,
      dialogVisible_applySuccess: false, 
      showScrollToBottom: true, 
      isSummit: false,
      formInputField: [
        {
          label: 'memberName',
          prop: 'name',
          placeholder: 'placeholderName'
        },
        {
          label: 'email',
          prop: 'email',
          placeholder: 'placeholderEmail'
        },
        {
          label: 'phone',
          prop: 'phone',
          placeholder: 'placeholderphone'
        }
      ],
      registerConsumerForm: {
        name: '',
        phone: '',
        email: '',
        suggestion: '',
        is_check_terms_of_use: [] // api: bool // for ui, modify it when fetch api
      }
    }
  },
  computed: {
    showUncheckPersoInfoAgree() {
      return this.isSummit && this.registerConsumerForm.is_check_terms_of_use.length === 0
    }
  },
  methods: {
    async fetchRegister() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)'
      })
      const isCheckedAgree = this.registerConsumerForm.is_check_terms_of_use.length > 0

      const registerForm = {
        name: this.registerConsumerForm.name,
        email: this.registerConsumerForm.email,
        phone: this.registerConsumerForm.phone,
        suggestion: this.registerConsumerForm.suggestion,
        is_check_terms_of_use: isCheckedAgree
      }
      try {
        await registerConsumer(registerForm)
        this.dialogVisible_applySuccess = true
        loading.close()
      } catch (error) {
        console.error(error)
        loading.close()
      }
    },
    handleCloseSuccess() {
      this.dialogVisible_applySuccess = false
      this.handleCancel()
    },
    submitForm(registerConsumerForm) {
      this.$refs[registerConsumerForm].validate((valid) => {
        if (valid) {
          this.isSummit = true
          if (!this.showUncheckPersoInfoAgree) {
            this.fetchRegister()
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleCheckAndClose() {
      if (this.registerConsumerForm.is_check_terms_of_use.length) {
        this.dialogVisiblePersonalInfoAgree = false
        this.$refs.dialogContent.removeEventListener('scroll', this.checkShowScrollButton)
      }
    },
    handleResize() {
      this.$nextTick(() => {
        this.applySuccessDialogWidth = window.innerWidth < 768 ? '70%' : '40%'
      })
    },
    showPersonalInfoAgreeDialog() {
      this.dialogVisiblePersonalInfoAgree = true
      this.$nextTick(() => {
        this.checkShowScrollButton()
        this.$refs.dialogContent.addEventListener('scroll', this.checkShowScrollButton)
      })
    },
    scrollToBottom() {
      const dialogBody = this.$el.querySelector('.infoagree-content')
      dialogBody.scrollTo({
        top: dialogBody.scrollHeight,
        behavior: 'smooth'
      })
      this.showScrollToBottom = false
    },
    checkShowScrollButton() {
      const dialogContent = this.$refs.dialogContent
      if (dialogContent.scrollTop) {
        this.showScrollToBottom = false
      }
    },
    handleCancel() {
      this.$router.push({ name: 'Register' })
    },
    emitClosePersonalInfoAgreeDialog() {
      this.dialogVisiblePersonalInfoAgree = false
      this.showScrollToBottom = true
      this.$refs.dialogContent.removeEventListener('scroll', this.checkShowScrollButton)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.consumer-form-container{
  min-height: 100vh;
  padding-top: 10vh;
  background-color: $background_blue;
  display: flex;
  justify-content: center;
  .consumer-form-main-section{
    border-radius: 4px;
    background-color: white;
    width: 80vw;
    height: fit-content;
    padding: 50px;
    padding-top: 20px;
  }

  @media (min-width: 769px){
    .desk-view{
      display: block;
    }
    .pad-phone-view{
      display: none;
    }
  }
  @media (max-width: 768px){
    .desk-view{
      display: none;
    }
    .pad-phone-view{
      display: block;
    }
  }
  .form-title{
    text-align: center;
    @media (max-width: 768px){
      h2{
        font-size: 1.25rem;
      }
    }
  }
  
  .form-footer{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-size: 0.875rem;
    a{
      color: $link;
      cursor: pointer;
    }
    a:hover{
      font-weight: bold;    
    }
  }
  
  .infoagree-area{
    padding-left: 0.6rem;
    margin-top: 30px;
    .dialog-title{
      text-align: center;
      @media (max-width: 768px){
        h2{
          font-size: 1.25rem;
        }
      }
    }
    
    span{
      color:$danger
    }
    .infoagree-desc{
      word-break: normal;
      margin-bottom: 1rem;
      .noncheck{
        color: $danger;
      }
      .checked{
        color: $success;
      }
    }
    .infoagree-button{
      margin-left: 0.625rem;
      color:$text_light;
      background-color: $background_dark;
    }
    .infoagree-button:hover{
      color:$background_dark;
      background-color: $text_light;
    }
    .noncheck-notify{
      color: $danger;
      margin: 0.625rem;
    }
  }
  .infoagree-dialog{
    background-color: $background_dark;
    text-align: center;
    h2{
        margin-bottom: 0;
      }
    .infoagree-content{
      border: 1px solid $text_dark;
      margin: 0 auto;
      width: 90%;
      height: 60vh;
      overflow-y: scroll;
      position: relative;
      @media (min-width: 1281px){
        height: 700px;
      }
      .scroll-bottom{
        position: absolute;
        bottom: 100px;
        left: 50%;
        transform: translateX(-50%);
        padding: 15px;
        box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.5);
      }
      .checkbox-container{
        padding: 0 0.625rem;
        .checkbox-text{
          color: $danger;
        }
      }
    }
  }
}
</style>
